<template>
  <el-dialog
    :title="type==='create'?'新增':'编辑'"
    :visible.sync="visible"
    custom-class="createDialog dataM"
    width="600px"
    :close-on-click-modal="false"
    @close="handleClose"
    >
    <el-form :model="form" :rules="rules" ref="ruleForm" class="dialogContent" :show-message="false">
      <div class="left">
        <el-form-item prop="resourceCode" class="item" label="编号">
          <el-input v-model.trim="form.resourceCode" clearable/>
        </el-form-item>
        <el-form-item prop="resourceName" class="item" label="名称">
          <el-input v-model.trim="form.resourceName" clearable/>
        </el-form-item>
        <el-form-item prop="quantityUnit" class="item" label="单位">
          <el-select v-model="form.quantityUnit">
            <el-option v-for="(v,i) in quantityUnitList" :key="i" :value="v" :label="v"/>
          </el-select>
        </el-form-item>
      </div>
      <div class="right">
        <el-form-item prop="totalQuantity" class="item" label="总数量">
          <el-input v-model.trim="form.totalQuantity" clearable/>
        </el-form-item>
        <el-form-item prop="availableQuantity" class="item" label="可用数量">
          <el-input v-model.trim="form.availableQuantity" clearable/>
        </el-form-item>
        <el-form-item prop="resourcesStatus" class="item" label="状态">
          <el-select v-model="form.resourcesStatus">
            <el-option v-for="(v,i) in resourcesStatus" :key="i" :value="v.value" :label="v.label"/>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取消</el-button>
      <el-button type="primary" @click="handleSure()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { 
  addAssistant,
  editAssistant 
  } from '@/api/data.js'
export default {
  name:'createStaff',
  props:{
    currentData:{
      require:false,
      default:()=>{}
    },
    createVisible:{
      require:true,
      default:false
    },
    resourceType:{
      
    },
    type:{
      default:'edit'
    }
  },
  data(){
    return{
      that:this,
      form:{
        resourceCode:'',
        totalQuantity:'',
        resourceName:'',
        availableQuantity:'',
        quantityUnit:'',
        resourcesStatus:'',
        resourceType:this.resourceType
      },
      rules:{
        resourceCode:[
          { required: true, message: '请输入编号', trigger: 'change' },
        ],
        totalQuantity:[
          {required: true, message: '请输入总数量', trigger: 'change'}
        ],
        resourceName:[
          {required: true, message: '请输入资源名称', trigger: 'change'}
        ],
        availableQuantity:[
          {required: true, message: '请输入可用数量', trigger: 'change'}
        ],
        quantityUnit:[
          {required: true, message: '请输入单位', trigger: 'change'}
        ],
        resourcesStatus:[
          {required: true, message: '请输入状态', trigger: 'change'}
        ]
      },
      resourcesStatus:[
        {
          label:'确认',
          value:1
        },{
          label:'未确认',
          value:2
        }
      ],
      quantityUnitList:['套','把','支','件/个']
    }
  },
  computed:{
    visible:{
      get(){
        return this.createVisible
      },
      set(){}
    },
  },
  watch:{
    createVisible(val){
      if(val){
        this.form = {...this.currentData}
      }
    },
    // currentData(){
    //   this.form = {
    //   ...this.currentData
    //   }
    // }
  },
  methods:{
    handleClose(){
      this.$emit('update:createVisible',false)
    },
    handleSure(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.handleSubmit()
        } else {
          this.$message.error('请补充完整信息')
          return false;
        }
      });
    },
    handleSubmit(){
      let params = {
        ...this.form
      }
      let Api = this.type==='create'?addAssistant:editAssistant
      Api(params).then(res=>{
        if(res.code == 0){
          this.$emit('update:createVisible',false)
          this.$emit('handleSearch')
          this.$message.success('操作成功')
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    //添加保养项目
    addItem(){
      this.form.maintenanceItems.push({value:""})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
<style lang="scss">
.createDialog{
  .el-form-item{
    margin-bottom:10px!important;
  }
  .el-form-item__label{
    font-size: 14px;
    line-height: 28px!important;
  }
  .el-select,.el-input{
    width: 100%!important;
  }
  .el-form-item.is-error .el-input__inner{
    border-color: #F56C6C!important;
  }
  .dialogContent{
    display:flex;
    .right,.left{
      display: flex;
      flex-direction: column;
      width:48%;
      .item{
        .item2{
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>